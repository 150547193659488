import {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";
import {debounce} from "lodash";
import FaqCard from "./FaqCard";

export const Faq = ({ data }) => {
  const [t, i18n] = useTranslation("common");
  const [searchedData, setSearchedData] = useState(data);
  // eslint-disable-next-line
  const [isOnFAQPage, setIsOnFAQPage] = useState(window.location.pathname === "/FAQ")
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setSearchedData(data);
  }, [data]);

  useEffect(()=>{
    if(window.location.pathname !== "/FAQ"){
        setSearchedData(data.slice(0,3))
    }
  },[data])

  const handleSearch = (e) => {
    if (!isSearching) {
      setIsSearching(true)
    }
    debouncedSearch({value: e.target.value, setter: setIsSearching})
  }

  const debouncedSearch = useCallback(
    debounce(({value, setter}) => {
      if (value !== "") {
        let search = data.filter(
          (dataObj) =>
            dataObj.labelEn.toLowerCase().includes(value.toLowerCase()) ||
            dataObj.labelDe.toLowerCase().includes(value.toLowerCase())
        );
        setSearchedData(search);
      } else {
        setSearchedData(data);
      }
      setter(false)
    }, 700),
    []
  );

  return (
    <section id="FAQ" className={styles.wrapper}>
      <div className={isOnFAQPage ? styles.faqPageContainer : styles.container}>
        {isOnFAQPage ? (
          <div className={styles.headerFaq}>
            <h2 className={styles.text}>FAQ</h2>
            <input
              className={styles.searchInput}
              onChange={handleSearch}
              placeholder={t("faq.searchPlaceholder")}
            />
          </div>
        ) : (
          <UnderlineHeader text={"FAQ"} align={"center"} width={"60%"} />
        )}
        <p className={styles.headerParagraph}>
          {t("faq.here")}
          <b>&nbsp;+41 44 840 33 40 or &nbsp;</b>
          <a className={styles.headerMail} href={"mailto: hello@step2real.ch"}>
            hello@step2real.ch
          </a>
          &nbsp;.
        </p>
        {searchedData.length > 0 ? (
          <div className={styles.faqCards}>
            {searchedData.map((faqCard, i) => (
              <FaqCard
                key={i}
                index={i + 1}
                img={faqCard.img || ""}
                text={i18n.language === "en" ? faqCard.textEn : faqCard.textDe}
                label={i18n.language === "en" ? faqCard.labelEn : faqCard.labelDe}
              />
            ))}
              <div className={`${styles.loading} ${isSearching ? styles.show : styles.hide}`}>
                <div className={styles.spin}>
                  <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="64px" height="64px" fill="#da291c" aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"/>
                  </svg>
                </div>
              </div>
          </div>
          ) : (
          <div className={styles.empty}>
            <p>{t("faq.empty")}</p>
            <div className={`${styles.loading} ${isSearching ? styles.show : styles.hide}`}>
              <div className={styles.spin}>
                <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="64px" height="64px" fill="#da291c" aria-hidden="true">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"/>
                </svg>
              </div>
            </div>
          </div>
          )
        }
        {!isOnFAQPage && (
          <button
            className={styles.readButton}
            onClick={() => {
              window.location.href = window.location.origin + "/FAQ"
            }}
          >
            {t("faq.readMore")}
          </button>
        )}
      </div>
    </section>
  );
};

export default Faq;
