import Faq from "../../components/Faq";
import Footer from "../../components/Footer";
import dataFaq from "../../data/FAQ.json";

export const FaqPage = () => {
  return (
    <>
      <Faq data={dataFaq}/>
      <Footer />
    </>
  );
};

export default FaqPage;
