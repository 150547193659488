import { useEffect, useState } from "react";
import styles from "./index.module.css";

export const UnderlineHeader = ({ text, width, align, color }) => {
  const [colorFor, setColor] = useState("#171717");

  useEffect(() => {
    if (color) setColor(color);
  }, [color]);

  return (
    <div
      style={{
        width: width,
        justifyContent: align,
        minWidth: "fit-content"
      }}
      className={styles.underline}
    >
      <h2 style={{ color: colorFor }} className={styles.text}>
        {text}
      </h2>
    </div>
  );
};

export default UnderlineHeader;
