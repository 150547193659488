import { AnimationOnScroll } from "react-animation-on-scroll";
import styles from "./index.module.css"

export const NewsCard = ({img, quartile, year, text, parity}) => {
  if (parity === "even") {
    return (
      <div className={styles.container_odd}>
        <AnimationOnScroll className={styles.content_odd} animateIn="animate__fadeInLeft" animateOnce={true} delay={100}>
          <h1 className={styles.quartile_header}>
            <span style={{ color: "#e1251b"}}>{quartile}</span>.{year}
          </h1>
          <p className={styles.text}>{text}</p>
        </AnimationOnScroll>
        <div className={styles.img_column_odd}>
          <div className={styles.img_container_odd}>
            <img className={styles.img} src={img} alt={""}/>
          </div>
        </div>
      </div>
    )
  }
  else if (parity === "odd") {
    return (
      <div className={styles.container_even}>
        <AnimationOnScroll className={styles.content_even} animateIn="animate__fadeInRight" animateOnce={true} delay={100}>
          <h1 className={styles.quartile_header}>
            <span style={{ color: "#e1251b"}}>{quartile}</span>.{year}
          </h1>
          <p className={styles.text}>{text}</p>
        </AnimationOnScroll>
        <div className={styles.img_column_even}>
          <div className={styles.img_container_even}>
            <img className={styles.img} src={img} alt={""}/>
          </div>
        </div>
      </div>
    )
  }
  else return null;
}

export default NewsCard;