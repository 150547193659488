import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";

export const TermsOfUse = () => {
  const [t] = useTranslation("common");
  return (
    <section id="termsOfUse" className={styles.wrapper}>
      <div className={styles.container}>
        <UnderlineHeader
          text={t("navbar.terms")}
          align={"left"}
          width={"60%"}
          color={"white"}
        />
        <div className={styles.div}>
          <UnderlineHeader
            text={t("terms.general")}
            align={"left"}
            width={"60%"}
            color={"white"}
          />
          <p>{t("terms.readHere")}</p>
          <a
            href={"/PDFs/Terms of use V3_DE_Gelso.pdf"}
            download="Terms of use Gelso"
            target="_blank"
            rel="noreferrer"
          >
            <button>[PDF Terms of use V3_DE]</button>
          </a>
        </div>
        <div className={styles.div} style={{ marginBottom: 0 }}>
          <UnderlineHeader
            text={t("terms.privacy")}
            align={"left"}
            width={"60%"}
            color={"white"}
          />
          <p>{t("terms.concern")}</p>
          <a
            href={"PDFs/Privacy Policy V2_DE_Gelso.pdf"}
            download="Privacy Policy Gelso"
            target="_blank"
            rel="noreferrer"
          >
            <button>[Doc Privacy Policy V2_DE_Gelso]</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
