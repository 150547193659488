import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";

export const AboutUs = () => {
  const [t] = useTranslation("common");
  return (
    <section id="aboutUs" className={styles.wrapper}>
      <div className={styles.container}>
        <UnderlineHeader
          text={t("navbar.aboutUs")}
          align={"center"}
          width={"50%"}
          color={"white"}
        />
        <div className={styles.split}>
          <div className={styles.left}>
            <p>{t("aboutUs.p1")}</p>
            <p>{t("aboutUs.p2")}</p>
            <p>{t("aboutUs.p3")}</p>
            <p>
              {t("aboutUs.p4a")}
              <span
                className={styles.gelsoLink}
                onClick={() => {
                  window.open("https://www.gelso.ch/en_GB/")
                }}
              >
                www.gelso.ch
              </span>
              {t("aboutUs.p4b")}
            </p>
            <p>{t("aboutUs.p5")}</p>
          </div>
          <div className={styles.right}>
            <div className={styles.img_container}>
              <img className={styles.img} src={"/pictures/team2.png"} alt={""}/>
            </div>
            <div className={styles.img_container}>
              <img className={styles.img} src={"/pictures/team.png"} alt={""}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutUs;
