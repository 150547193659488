import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";
import {useCallback, useEffect, useState} from "react";
import DesignTip from "./DesignTip";
import i18next from "i18next";
import {debounce} from "lodash";

export const DesignRecommend = ({ data }) => {
  const [t] = useTranslation("common");
  const [searchedData, setSearchedData] = useState(data);
  const [isOnRecommendPage, setIsOnRecommendPage] = useState(window.location.pathname === "/Recommend")
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setSearchedData(data);
  }, [data]);

  useEffect(()=>{
    if(window.location.pathname !== "/Recommend"){
      setSearchedData(data.slice(0,3))
    }
  },[data])

  const handleSearch = (e) => {
    if (!isSearching) {
      setIsSearching(true)
    }
    debouncedSearch({value: e.target.value, setter: setIsSearching})
  }

  const debouncedSearch = useCallback(
    debounce(({value, setter}) => {
      if (value !== "") {
        let search = data.filter(
          (dataObj) =>
            dataObj.labelEn.toLowerCase().includes(value.toLowerCase()) ||
            dataObj.labelDe.toLowerCase().includes(value.toLowerCase())
        );
        setSearchedData(search);
      } else {
        setSearchedData(data);
      }
      setter(false)
    }, 700),
    []
  );

  return (
    <section
      className={styles.wrapper}
      style={isOnRecommendPage ? { background: "white" } : {}}
    >
      <div className={isOnRecommendPage ? styles.recommendContainer : styles.container}>
        {isOnRecommendPage ? (
          <div className={styles.headerFaq}>
            <h2 className={styles.title}>{t("design.recommendation")}</h2>
            <input
              className={styles.searchInput}
              onChange={handleSearch}
              placeholder={t("design.searchPlaceholder")}
            />
          </div>
        ) : (
          <UnderlineHeader
            text={t("design.recommendation")}
            align={"left"}
            width={"60%"}
            color={!isOnRecommendPage ? "white" : "#262f33"}
          />
        )}
        {(isOnRecommendPage ? (
          searchedData.length > 0 ? (
            <div className={styles.middleSplit}>
              <div className={styles.left}>
                <p className={styles.subTitle}>{t("design.points")}</p>
                {searchedData.map((tip, i) => (
                  <DesignTip
                    key={i}
                    index={i + 1}
                    text={i18next.language === "en" ? tip.textEn : tip.textDe}
                    label={i18next.language === "en" ? tip.labelEn : tip.labelDe}
                    img={tip.img || ""}
                  />
                ))}
                <div className={`${styles.loading} ${isSearching ? styles.show : styles.hide}`}>
                  <div className={styles.spin}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="64px" height="64px" fill="#da291c" aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.empty}>
              <p>{t("faq.empty")}</p>
              <div className={`${styles.loading} ${isSearching ? styles.show : styles.hide}`}>
                <div className={styles.spin}>
                  <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="64px" height="64px" fill="#da291c" aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"/>
                  </svg>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className={styles.middleSplit}>
            {searchedData.length > 0 && (
              <div className={styles.left}>
                <p className={styles.subTitle}>{t("design.points")}</p>
                {searchedData.map((tip, i) => (
                  <DesignTip
                    key={i}
                    index={i + 1}
                    text={i18next.language === "en" ? tip.textEn : tip.textDe}
                    label={i18next.language === "en" ? tip.labelEn : tip.labelDe}
                    img={tip.img || ""}
                    onMainPage={true}
                  />
                ))}
                <div className={`${styles.loading} ${isSearching ? styles.show : styles.hide}`}>
                  <div className={styles.spin}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="64px" height="64px" fill="#da291c" aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"/>
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        {!isOnRecommendPage && (
          <div style={{ width: "100%"}}>
            <button
              className={styles.readButton}
              onClick={() => {
                window.location.href = window.location.origin + "/Recommend"
              }}
            >
              {t("faq.readMore")}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default DesignRecommend;
