import {useTranslation} from "react-i18next";
import styles from "./index.module.css";
import {ReactComponent as LangIcon} from "../../icons/lang.svg";
import {ReactComponent as StepIcon} from "../../icons/step2real.svg";
import {useEffect, useState} from "react";

export const Navbar = () => {
  const [t, i18n] = useTranslation("common");
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("selectedLanguage") || "en")
  const [showButtons, setShowButton] = useState(false);
  const [isNavHidden, setIsNavHidden] = useState(true);

  const handleLanguageChange = (language) => {
    localStorage.setItem('selectedLanguage', language);
    setSelectedLanguage(language)
    i18n.changeLanguage(language);
    setShowButton(false);
  }

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [i18n, selectedLanguage])

  const hideNavbar = () => {
    setIsNavHidden(!isNavHidden);
  };
  return (
    <header onClick={() => hideNavbar()} className={`${isNavHidden && styles.hiddenHeader}`}>
      <a className={styles.logo} href="/#">
        <StepIcon />
      </a>
      <div className={`${styles.links} ${isNavHidden && styles.hidden}`}>
        <div>
          <a onClick={() => hideNavbar()} href="/#howItWorks">
            {t("navbar.howItWorks")}
          </a>
        </div>
        <div>
          <a onClick={() => hideNavbar()} href="/#prices">
            {t("navbar.prices")}
          </a>
        </div>
        <div>
          <a onClick={() => hideNavbar()} href="/#aboutUs">
            {t("navbar.aboutUs")}
          </a>
        </div>
        <div>
          <a onClick={() => hideNavbar()} href="/#termsOfUse">
            {t("navbar.terms")}
          </a>
        </div>
        <div>
          <a onClick={() => hideNavbar()} href="/#FAQ">
            FAQ
          </a>
        </div>
        <div>
          <a onClick={() => hideNavbar()} href="/#contact">
            {t("navbar.contact")}
          </a>
        </div>
      </div>
      <div className={`${styles.buttonsLeft} ${isNavHidden && styles.hidden}`}>
        <div className={styles.select}>
          <button
            className={styles.show}
            onClick={(e) => {
              setShowButton(!showButtons);
              e.stopPropagation();
            }}
          >
            <LangIcon />
            {i18n.language.toUpperCase()}
          </button>
          {showButtons && (
            <div className={styles.langButtons}>
              <button
                onClick={(e) => {
                  handleLanguageChange("de")
                  e.stopPropagation()
                }}
              >
                <b>DE</b>
              </button>
              <button
                onClick={(e) => {
                  handleLanguageChange("en")
                  e.stopPropagation()
                }}
              >
                <b>EN</b>
              </button>
            </div>
          )}
        </div>
        <button
          className={styles.navButton}
          onClick={(e) => {
            let { protocol, host } = window.location;
            if(host.split('.').length === 3) {
              host = host.split('.').slice(1).join('.')
            }
            window.location.href = protocol + '//app.' + host;
            e.stopPropagation()
          }}
        >
          {t("navbar.getQuote")}
        </button>
      </div>
    </header>
  );
};

export default Navbar;
