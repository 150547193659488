import DesignRecommend from "../../components/DesignRecommend"
import Footer from "../../components/Footer"
import dataRecommend from "../../data/Recommend.json";
export const RecommendPage = () =>{
    return(
        <>
        <DesignRecommend data={dataRecommend}/>
        <Footer/>
        </>
    )
}

export default RecommendPage;