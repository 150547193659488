import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";

export const Prices = () => {
  const [t] = useTranslation("common");
  return (
    <section id="prices" className={styles.wrapper}>
      <div className={styles.container}>
        <UnderlineHeader
          text={t("navbar.prices")}
          width={"50%"}
          align={"left"}
        />
        <div className={styles.customers}>
          <h3>{t("prices.customers")}</h3>
          <p>{t("prices.developer")}</p>
        </div>
        <div className={styles.producers}>
          <h3>{t("prices.producers")}</h3>
          <div className={styles.middleSplit}>
            <div className={styles.left}>
              <p>{t("prices.gratisP1")} <b>hello@step2real.ch</b>.</p>
              <p>{t("prices.gratisP2")}</p>
            </div>
            <div className={styles.right}>
              <img className={styles.producersImg} src={"/pictures/gratis.png"} alt={"GRATIS"}/>
            </div>
          </div>
          <p>{t("prices.gratisP3")}</p>
        </div>
        <img className={styles.img} src={"/pictures/audit.png"} alt={""}/>
      </div>
    </section>
  );
};

export default Prices;
