import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

export const QuoteButton = () => {
  const [t] = useTranslation("common");
  return (
    <button
      className={styles.button}
      onClick={() => {
        let { protocol, host } = window.location;
        if(host.split('.').length === 3) {
          host = host.split('.').slice(1).join('.')
        }
        window.location.href = protocol + '//app.' + host;
      }}
    >
      {t("navbar.getQuote")}
    </button>
  );
};

export default QuoteButton;
