import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import AdvantageCard from "./AdvantageCard";
import style from "./index.module.css";

export const Advantages = () => {
  const [t] = useTranslation("common");
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <UnderlineHeader
          text={t("advantages.advantages")}
          width={"45%"}
          align={"left"}
          color={"white"}
        />
        <p className={style.p}>{t("advantages.differs")}</p>
        <div className={style.cards}>
          <AdvantageCard text={t("advantages.card1")} alt={t("advantages.card1alt")} src={"/pictures/group.png"} delay={0}/>
          <AdvantageCard text={t("advantages.card2")} alt={t("advantages.card2alt")} src={"/pictures/colab.png"} delay={150}/>
          <AdvantageCard text={t("advantages.card3")} alt={t("advantages.card3alt")} src={"/pictures/assistance.png"} delay={300}/>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
