import "../../App.css";
import AboutUs from "../../components/AboutUs";
import Advantages from "../../components/Advantages";
import ContactImprint from "../../components/ContactImprint";
import DesignRecommend from "../../components/DesignRecommend";
import Faq from "../../components/Faq";
import Footer from "../../components/Footer";
import GetPartPrice from "../../components/GetPartPrice";
import HowItWorks from "../../components/HowItWorks";
import IntroSection from "../../components/IntroSection";
import { News } from "../../components/News";
import Possibilities from "../../components/Possibilities";
import Prices from "../../components/Prices";
import TermsOfUse from "../../components/TermsOfUse";
import faqData from "../../data/FAQ.json"
import newsData from "../../data/News.json"
import recommendData from "../../data/Recommend.json"

function LandingPage() {
  return (
    <div className="App">
      <IntroSection />
      <HowItWorks />
      <GetPartPrice />
      <Possibilities />
      <Advantages />
      <Prices />
      <AboutUs />
      <News data={newsData}/>
      <TermsOfUse />
      <Faq data={faqData} />
      <DesignRecommend data={recommendData}/>
      <ContactImprint />
      <Footer />
    </div>
  );
}

export default LandingPage;