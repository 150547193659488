import styles from "./index.module.css";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import QuoteButton from "../QuoteButton";
import { useTranslation } from "react-i18next";
export const IntroSection = () => {
  const [t] = useTranslation("common");
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.left}>
          <h1 className={styles.h1}>{t("intro.gateway")}</h1>
          <p className={styles.p1}>{t("intro.calculate")}</p>
          <ul className={styles.ul}>
            <li className={styles.li}>{t("intro.protecting")}</li>
            <li className={styles.li}>{t("intro.appeals")}</li>
          </ul>
          <QuoteButton />
          <div className={styles.disclaimer}>
            <LockIcon /> {t("intro.confidential")}
          </div>
        </div>
        <div className={styles.right}>
          <video className={styles.video} autoPlay muted loop onContextMenu={e => e.preventDefault()}>
            <source src={"/videos/cockpit_compressed_max.mp4"} type={"video/mp4"} />
          </video>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
