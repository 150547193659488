import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";

export const Possibilities = () => {
  const [t] = useTranslation("common");
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <UnderlineHeader
          text={t("ourPossibilities.possibilities")}
          width={"45%"}
          align={"left"}
        />
        <p>{t("ourPossibilities.procedure")}</p>
        <div className={styles.table}>
          <div className={styles.leftRow}>
            <div>{t("ourPossibilities.table.left.p1")}</div>
            <div>{t("ourPossibilities.table.left.p2")}</div>
            <div>{t("ourPossibilities.table.left.p3")}</div>
            <div>{t("ourPossibilities.table.left.p4")}</div>
            <div>{t("ourPossibilities.table.left.p5")}</div>
            <div>{t("ourPossibilities.table.left.p6")}</div>
            <div>{t("ourPossibilities.table.left.p7")}</div>
            <div>{t("ourPossibilities.table.left.p8")}</div>
            <div>{t("ourPossibilities.table.left.p9")}</div>
          </div>
          <div className={styles.rightRow}>
            <div>{t("ourPossibilities.table.right.calculation")}</div>
            <div>{t("ourPossibilities.table.right.auto")}</div>
            <div>{t("ourPossibilities.table.right.auto")}</div>
            <div>{t("ourPossibilities.table.right.auto")}</div>
            <div>{t("ourPossibilities.table.right.auto")}</div>
            <div>{t("ourPossibilities.table.right.manual")}</div>
            <div>{t("ourPossibilities.table.right.manual")}</div>
            <div>{t("ourPossibilities.table.right.overHead")}</div>
            <div>{t("ourPossibilities.table.right.manual")}</div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div>
            <p>{t("ourPossibilities.vision")}</p>
            <p>{t("ourPossibilities.after")}</p>
          </div>
          <img className={styles.bottomImg} src={"/pictures/machine1.png"}  alt={""}/>
        </div>
      </div>
    </section>
  );
};

export default Possibilities;
