import { useState } from "react";
import ItemCard from "../ItemCard";
import QuoteButton from "../QuoteButton";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";

export const HowItWorks = () => {
  const [sheetData] = useState([
    { name: "Raw material costs:", value: "Based on weight" },
    { name: "Cutting times:", value: "Based on a simulation" },
    { name: "Bendings:", value: "By counting bends" },
    { name: "Tolerance class:", value: "By your choice" },
    { name: "Surface treatment:", value: "By your choice" },
  ]);
  const [sheetDataDE] = useState([
    { name: "Rohmaterialkosten:", value: "aufgrund des Gewichts" },
    { name: "Schneidzeiten:", value: "aufgrund einer Simulation" },
    { name: "Abkantungen:", value: "mittels einer Auszählung" },
    { name: "Toleranzklasse:", value: "mittels Ihrer Auswahl" },
    { name: "Oberflächenbehandlung:", value: "mittels Ihrer Auswahl" },
  ]);
  const [milledData] = useState([
    { name: "Raw material costs:", value: "Based on weight" },
    { name: "Milling-turning time:", value: "Based on a simulation" },
    { name: "Tolerance class:", value: "By your choice" },
    { name: "Surface treatment:", value: "By your choice" },
  ]);
  const [milledDataDE] = useState([
    { name: "Rohmaterialkosten:", value: "aufgrund des Gewichts" },
    { name: "Fräs- Drehzeit:", value: "aufgrund einer Simulation" },
    { name: "Toleranzklasse:", value: "mittels Ihrer Auswahl" },
    { name: "Oberflächenbehandlung:", value: "mittels Ihrer Auswahl" },
  ]);

  const [t, i18n] = useTranslation("common");
  return (
    <section id="howItWorks" className={styles.wrapper}>
      <div className={styles.container}>
        <UnderlineHeader text={t("navbar.howItWorks")} width={"50%"} />
        <p className={styles.p}>{t("howItWorks.p1")}</p>
        <QuoteButton />
        <p className={`${styles.p} ${styles.leftP}`}>{t("howItWorks.p2")}</p>
        <div className={styles.itemCards}>
          <ItemCard
            label={t("howItWorks.sheet")}
            picture={"/pictures/sheet_metal.png"}
            data={i18n.language === "en" ? sheetData : sheetDataDE}
          />
          <ItemCard
            label={t("howItWorks.turned")}
            picture={"/pictures/milling.jpg"}
            data={i18n.language === "en" ? milledData : milledDataDE}
          />
        </div>
        <div className={styles.explainer}>
          <img className={styles.equalizerImg} src={"pictures/equalizer.png"} alt={""}/>
          <p className={styles.explainerP}>{t("howItWorks.p3")}</p>
        </div>
        <div className={styles.quoteExplainer}>
          <p className={styles.explainerP}>
            {t("howItWorks.p4a")}
            {i18n.language === "en" ?
              <span style={{ color: "black" }}>"<b>+RFQ</b>"</span>
              :
              <span style={{ color: "black" }}>«<b>+RFQ</b>»</span>
            }
            {t("howItWorks.p4b")}
          </p>
          <img src={"/pictures/RFQ1.png"} className={styles.img} alt={"+RFQ"}/>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
