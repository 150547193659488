import {useState} from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const CookieNotification = () => {
  const [t] = useTranslation("common");
  const [acceptedCookies, setAcceptedCookies] = useState(Boolean(localStorage.getItem("cookiesAccepted")) || false);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', "true")
    setAcceptedCookies(true);
  };

  if (acceptedCookies) {
    return null;
  }

  return (
    <div className={styles.cookieContainer}>
      <div className={styles.cookieContent}>
        <h2>{t("cookies.title")}</h2>
        <p className={styles.text}>{t("cookies.description")}</p>
        <p className={styles.text}>{t("cookies.consent")}</p>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={acceptCookies}
          >
            {t("cookies.accept")}
          </button>
          <div className={styles.privacyContainer}>
            <a
              className={styles.hyperlink}
              href={"/PDFs/Terms of use V3_DE_Gelso.pdf"}
              download="Terms of use Gelso"
              target="_blank"
              rel="noreferrer"
            >
              <u>Terms of Use</u>
            </a>
            <a
              className={styles.hyperlink}
              href={"PDFs/Privacy Policy V2_DE_Gelso.pdf"}
              download="Privacy Policy Gelso"
              target="_blank"
              rel="noreferrer"
            >
              <u>Privacy Policy</u>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieNotification;