import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";

export const ContactImprint = () => {  
  const [t] = useTranslation("common");
  return (
    <section id="contact" className={styles.wrapper}>
      <div className={styles.container}>
        <UnderlineHeader text={t("navbar.contact")} width={"60%"} align={"center"} />
        <div className={styles.contacts}>
          {" "}
          <p>
           {t("contact.mail")}{" "}
            <a className={styles.mailLink} href="mailto: hello@step2real.ch">
              hello@step2real.ch
            </a>{" "}
            .
          </p>
          <p>
            {t("contact.phone")} <b>+41 44 840 33 40</b>.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactImprint;
