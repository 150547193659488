import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import FaqPage from "./pages/FaqPage";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import RecommendPage from "./pages/RecommendPage";
import CookieNotification from "./components/CookieNotification";

function App() {
  return (
    <div className="App">
      <Router>
        <CookieNotification />
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/FAQ" element={<FaqPage />} />
          <Route exact path="/Recommend" element={<RecommendPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
