import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
export const GetPartPrice = () => {
  const [t] = useTranslation("common");
  return (
    <section id="getPartPrice" className={styles.wrapper}>
      <div className={styles.container}>
        <AnimationOnScroll className={styles.left} animateIn="animate__fadeInLeft" animateOnce={true}>
          <UnderlineHeader text={t("partPrice.getPartPrice")} width={"100%"} align={"left"} />
          <ul className={styles.list}>
            <li>{t("partPrice.upload")}</li>
            <li>{t("partPrice.selectRaw")}</li>
            <li>{t("partPrice.selectClosest")}</li>
            <li>{t("partPrice.doubleCheck")}</li>
            <li>{t("partPrice.treatment")}</li>
            <li>{t("partPrice.offer")}</li>
          </ul>
        </AnimationOnScroll>
        <AnimationOnScroll className={styles.right} animateIn="animate__fadeInRight" animateOnce={true}>
          <iframe
            className={styles.video}
            width="530"
            height="300"
            src="https://www.youtube.com/embed/Snckv-LCPC4?autoplay=1&rel=0&cc_load_policy=0&start=0&end=0&vq=hd1080"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </AnimationOnScroll>
      </div>
    </section>
  );
};
export default GetPartPrice;
