import styles from "./index.module.css";
export const ItemCard = ({ label, picture, data }) => {
  return (
    <div className={styles.card}>
      <h3 className={styles.label}>{label}</h3>
      <img className={styles.picture} src={picture} alt={""} />
      <div className={styles.desc}>
        {data &&
          data.map((plus, i) => (
            <p className={styles.p} key={i}>
              <span className={styles.b}>
                {plus.name}&nbsp;
              </span>
              <span>
                {plus.value}
              </span>
            </p>
          ))}
      </div>
    </div>
  );
};

export default ItemCard;
