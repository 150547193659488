import styles from "./index.module.css"
import { AnimationOnScroll } from "react-animation-on-scroll";

export const AdvantageCard = ({src, text, alt, delay}) => {
  return (
    <AnimationOnScroll className={styles.card} animateIn={"animate__fadeInUp"} animateOnce={true} delay={delay}>
      <img src={src} className={styles.icon} alt={alt}/>
      <p className={styles.p}>{text}</p>
    </AnimationOnScroll>
  )
}

export default AdvantageCard;