import styles from "./index.module.css";

export const FaqCard = ({ img, index, text, label, color }) => {
  return (
    <div className={styles.faqCard}>
      <div className={styles.index}>
        {index}.
      </div>
      <div className={styles.card}>
        <div className={styles.question}>
          <span className={styles.q}>Q.</span>
          <p className={styles.l} style={color ? { color: color } : { color: "#262f33" }}>
            {label}
          </p>
        </div>
        <div className={styles.answer}>
          <div className={styles.answerText}>
            <span className={styles.a}>A.</span>
            <p className={styles.p} style={color ? { color: color } : { color: "#262f33" }}>
              {text}
            </p>
          </div>
          {img !== "" && (
            <div className={styles.imageContainer}>
              <img src={img} className={styles.img} alt={""}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqCard;
