import styles from "./index.module.css";

const DesignTip = ({index, label, text, img, onMainPage=false}) => {

  return (
    <div className={styles.tipContainer}>
      <div className={styles.textContainer}>
        <h2 style={onMainPage ? {color: "white", marginBottom: 24} : {marginBottom: 24}}>
          <span className={styles.labelNumber}>#{index}</span>
          {label}
        </h2>
        <p style={onMainPage ? {color: "white"} : {}}>{text}</p>
      </div>
      {img !== "" && (
        <div className={styles.imageContainer}>
          <img src={img} className={styles.img} alt={""}/>
        </div>
      )}
    </div>
  )
}

export default DesignTip