import { useTranslation } from "react-i18next";
import UnderlineHeader from "../UnderlineHeader";
import styles from "./index.module.css";
import NewsCard from "./NewsCard";

export const News = ({ data }) => {
  const [t, i18n] = useTranslation("common");
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <UnderlineHeader text={t("news.news")} align={"left"} width={"40%"} />
        <div className={styles.newsCards}>
          {data &&
            data.map((news, i) => (
              <NewsCard
                key={i}
                img={news.src}
                quartile={news.quartile}
                year={news.year}
                text={i18n.language === "en" ? news.textEn : news.textDe}
                parity={i % 2 === 0 ? "even" : "odd"}
              />
            ))}
        </div>
      </div>
    </section>
  );
};
