import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import axios from "axios";
import {useState} from "react";

export const Footer = () => {
  const [t] = useTranslation("common");
  const emailRegex = new RegExp('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')
  const [newsletterEmail, setNewsletterEmail] = useState("")
  const [emailIsValid, setEmailIsValid] = useState(false)

  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.info}>
          <img className={styles.logo} src={"/pictures/Gelso 1.png"} alt={"Gelso"}/>
          <div className={styles.infoContainer}>
            <div className={styles.infoPart}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ fill: "white", width: 36 }}>
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
              </svg>
              <div className={styles.infoData}>
                <p>Althardstrasse 120,</p>
                <p>CH-8105 Regensdorf</p>
              </div>
            </div>
            <div className={styles.infoPart}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ fill: "white", width: 36 }}>
                <path d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39-2.57 0-4.66 1.97-4.66 4.39 0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94 1.7 0 3.08 1.32 3.08 2.94 0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"/>
              </svg>
              <div className={styles.infoData}>
                <p>VAT CHE-108.413.346</p>
              </div>
            </div>
            <div className={styles.infoPart}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ fill: "white", width: 36 }}>
                <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/>
              </svg>
              <div className={styles.infoData}>
                <p>Gelso AG</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.divider}/>
        <div className={styles.signUp}>
          <div className={styles.signUpArea}>
            <h2>{t("footer.signUp")}</h2>
            <div className={styles.mail}>
              <input
                type={"email"}
                id={"newsletterInput"}
                className={styles.input}
                onChange={(e)=>{
                  setNewsletterEmail(e.target.value)
                  if(emailRegex.test(e.target.value)) {
                    setEmailIsValid(true)
                  } else {
                    setEmailIsValid(false)
                  }
                }}
                placeholder={t("contact.mailPlaceholder")}
              />
              <button
                onClick={() => {
                  const { protocol, host } = window.location;
                  const url = protocol + '//app.' + host + '/api/auth/newsletter';
                  axios.post(
                    url,
                    JSON.stringify({email: newsletterEmail}),
                    {headers: {'Content-Type': 'application/json'}}
                  ).catch(()=>{})
                  document.getElementById("subModal").style.display = "block"
                  document.getElementById("newsletterInput").value = "";
                  setNewsletterEmail("")
                  setEmailIsValid(false)
                }}
                disabled={!emailIsValid}
                className={styles.subButton}
              >
                {t("footer.subscribe")}{!emailIsValid && <span className={styles.tooltipText}>{t("footer.invalidEmail")}</span>}
              </button>

            </div>
          </div>
          <p>{t("footer.offer")}</p>
        </div>
      </div>

      <div id="subModal" className={styles.modal}>
        <div className={styles.modalContent}>
          <span
            className={styles.close}
            onClick={() => {
              document.getElementById("subModal").style.display = "none"
            }}
          >
            &times;
          </span>
          <h2>{t("subscriptionModal.title")}</h2>
          <div className={styles.dividerVert}/>
          <p>{t("subscriptionModal.p1")}</p>
          <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false"
               aria-hidden="true" viewBox="0 0 24 24" data-testid="MarkEmailReadIcon"  style={{ fill: "#262f33", height: 64 }}>
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h8v-2H4V8l8 5 8-5v5h2V6c0-1.1-.9-2-2-2zm-8 7L4 6h16l-8 5zm5.34 11-3.54-3.54 1.41-1.41 2.12 2.12 4.24-4.24L23 16.34 17.34 22z"/>
          </svg>
          <p>{t("subscriptionModal.p2")}</p>
          <p>
            {t("subscriptionModal.p3a")}
            <b style={{ color: "#e1251b"}}>step2real</b>
            {t("subscriptionModal.p3b")} 📬
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
